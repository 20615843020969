<template>
  <v-col cols="12">
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card>
      <v-card-title>Moteurs</v-card-title>

      <v-card-text>
        <div class="global-actions">
          <router-link :to="{ name: 'engineCreate' }">
            <v-btn
              small
              color="primary"
              class="mt-5"
            >
              Ajouter un moteur
            </v-btn>
          </router-link>
        </div>
      </v-card-text>

      <div class="clear"></div>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-uppercase">
                Nom
              </th>
              <th class="text-center text-uppercase">
                ID Moteur
              </th>
              <th class="text-center text-uppercase">
                Status
              </th>
              <th class="text-center text-uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(engine, engineIndex) in engines"
              :key="'e' + engineIndex"
            >
              <td class="font-weight-bold">
                {{ engine.name ? engine.name : '-' }}
              </td>
              <td class="text-center">
                {{ engine.engineId ? engine.engineId : '-' }}
              </td>
              <td class="text-center">
                <v-chip
                  v-if="engine.enabled"
                  color="success"
                  text-color="white"
                  small
                >
                  Activé
                </v-chip>
                <v-chip
                  v-if="!engine.enabled"
                  color="error"
                  text-color="white"
                  small
                >
                  Désactivé
                </v-chip>
              </td>
              <td class="text-center">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="warning"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      :to="{ name: 'engineDetails', params: { engineId: engine.id }}"
                    >
                      <v-icon dark>
                        {{ icons.mdiEye }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Consulter</span>
                </v-tooltip>

                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      class="ml-2"
                      color="error"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      @click="openDeleteEngineDialog(engine)"
                    >
                      <v-icon dark>
                        {{ icons.mdiTrashCan }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Supprimer</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!engines.length">
            <tr>
              <td>
                <span v-if="loading === false">Aucun éléments.</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <DeleteEngineDialog
        :key="Date.now()"
        :delete-engine-dialog-state="deleteEngineDialogState"
        :engine="engineToDelete"
        @closeDeleteEngineDialog="closeDeleteEngineDialog($event)"
      ></DeleteEngineDialog>
    </v-card>
  </v-col>
</template>

<script>
import { mdiPlay, mdiPen, mdiEye, mdiTrashCan } from '@mdi/js'
import Vue from 'vue'
import DeleteEngineDialog from '@/components/Dialog/EngineDialog/DeleteEngineDialog'
import config from '../../config'
import Loader from '@/components/Common/Loader'

export default {
  components: {
    DeleteEngineDialog,
    Loader
  },
  data: () => ({
    engines: [],
    engineToDelete: null,
    deleteEngineDialogState: false,
    loading: false,
    icons: {
      mdiPlay,
      mdiPen,
      mdiEye,
      mdiTrashCan
    }
  }),
  created () {
    this.getEngines()
  },
  methods: {
    getEngines () {
      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/api/engines', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              this.engines = data
            }
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    openDeleteEngineDialog (engine) {
      this.deleteEngineDialogState = true
      this.engineToDelete = engine
    },
    closeDeleteEngineDialog (deleteState) {
      if (deleteState === true) {
        this.engines = this.engines.filter(element => element.id !== this.engineToDelete.id)
      }

      this.deleteEngineDialogState = false
    }
  }
}
</script>

